












































































































import {mapState} from "vuex";
import { GChart } from "vue-google-charts";
import AddLiquidity from "@/components/AddLiquidity.vue";
import filtersMixin from "@/mixins/filters";

export default {
    name: "LiquidityView",
    components: {
        GChart,
        AddLiquidity,
    },
    mixins: [filtersMixin],
    data: () => ({
        chartOptions: {
            backgroundColor: "transparent",
            pieHole: 0.5,
            pieSliceTextStyle: {
                color: "transparent"
            },
            legend: "none",
            chartArea: { height: "92%" },
            height: "220",
            colors: ['#F269A7', '#CE81ED', '#E4CBEE', '#f8f8f8']
        }
    }),
    computed: {
        ...mapState([
            'a',
            'balanceChartData',
            'total',
            'fee',
            'adminFee',
            'virtualPrice',
        ])
    }
}
