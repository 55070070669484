








































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapState, mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import BigNumber from "bignumber.js";

import filtersMixin from "@/mixins/filters";
import { CONTRACT, selectedNetwork } from "@/network";
import { Currency, GasPrice, GasPriceInfo } from "@/types";
import state from "@/contract";
import { checkOrApprove, getEthPrice, getImgUrl } from "@/helpers";
import { initToken } from "@/init";
import Alert from "@/components/UI/Alert.vue";
import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";

@Component({
    name: "AddLiquidity",
    mixins: [filtersMixin],
    components: {
        vSelect,
        Alert,
    },
    data: () => ({
        coins: state.coins
    }),
    computed: {
        ...mapState([
            'client',
            'setLiquidity3PoolToken',
            'balanceChartData'
        ]),
        ...mapGetters([
            'getGasPriceOptions'
        ])
    },
    methods: {
        getImgUrl,
        ...mapActions([
          'UPDATE_BALANCES'
        ]),
    },
})
export default class AddLiquidity extends Vue {
    // TODO: add type to client
    public client!: any;
    public setLiquidity3PoolToken!: BigNumber;
    private isAdvancedOptionsOpened = false;
    private isWithdrawActive = false;
    private isOptimalProportions = false;

    private showAlert = false;
    private isExchangePending = false;
    private transactionError = false;
    private isAmountErrors = false;
    private alertText = '';
    private alertTitle = '';
    private maxSlippage = '1.5';
    private delayTimer: any = null;

    private minLPTokensAmount = new BigNumber(0);

    private coins: Currency[] = state.coins;

    // loading token list from state
    private amounts: { [key: string]: any } = state.coins.reduce((obj, c) => ({
        ...obj,
        [c.address]: '',
    }), {})

    getGasPriceOptions!: GasPriceInfo[];
    private gasOptionsSelect = { label: '', value: new BigNumber(1e9) };
    private isInfinite = true;

    // TODO: when this is ON, calculate optimal proportions as balanced pool values
    // private useOptimalProportion = false;

    private ethPrice = 0; // USD
    private gasLimit = 300_000;
    private USE_GAS_LIMIT = 300_000;

    created () {
        this.init()
    }

    async init () {
        console.log('init')
        await getEthPrice(selectedNetwork.chain)

        this.ethPrice = state.ethPrice
        this.gasOptionsSelect = this.getGasPriceOptions.slice(-1)[0];
    }

    @Watch('getGasPriceOptions')
    onPropertyChanged(value: GasPriceInfo[]) {
        this.gasOptionsSelect = value.slice(-1)[0];
    }

    get gasPrice (): GasPrice {
        return new BigNumber(this.gasOptionsSelect.value)
    }

    openAdvOptions() {
      this.isAdvancedOptionsOpened = !this.isAdvancedOptionsOpened;
    }

    goToWithdraw() {
        this.$router.push('/withdraw')
    }

    changeWithdraw() {
        this.isWithdrawActive = !this.isWithdrawActive
    }

    get txCostUSD () {
        return (this.gasPrice.toNumber() * this.gasLimit * this.ethPrice) / 1e9;
    }

    get isAmountEmpty() {
        return Object.values(this.amounts).reduce((isEmpty: boolean, b: any) => isEmpty && !b, true)
    }

    shouldShowError(balance: BigNumber, amount: any) {
        return new BigNumber(amount).gt(balance) || new BigNumber(amount).lte(0)
    }

    shouldShowMaxButton(balance: any, amount: any) {
        const isAmountMax = new BigNumber(amount).isEqualTo(balance)

        return !!balance && !isAmountMax
    }

    activateOptimalProportions () {
        this.coins.forEach((coin, index) => {
            const amount = this.amounts[this.coins[index].address];
            if (!amount) { return }
            this.updateOptimalProportions(
              index,
              this.amounts[this.coins[index].address],
              this.$store.state.balanceChartData
            )
        })
    }

    updateOptimalProportions (index: any, val: any, balanceChartData: any) {
        if(!this.isOptimalProportions) return
        if (index === 0) {
            const usdc = balanceChartData[2];
            const usdt = balanceChartData[3];
            const dai = balanceChartData[1];
            this.amounts[this.coins[1].address] = (val * usdc[2] / dai[2])
            this.amounts[this.coins[2].address] = (val * usdt[2] / dai[2])
        }
        if (index === 1) {
            const dai = balanceChartData[1];
            const usdc = balanceChartData[2];
            const usdt = balanceChartData[3];
            this.amounts[this.coins[0].address] = (val * dai[2] / usdc[2])
            this.amounts[this.coins[2].address] = (val * usdt[2] / usdc[2])
        }
        if (index === 2) {
            const dai = balanceChartData[1];
            const usdc = balanceChartData[2];
            const usdt = balanceChartData[3];
            this.amounts[this.coins[0].address] = (val * dai[2] / usdt[2])
            this.amounts[this.coins[1].address] = (val * usdc[2] / usdt[2])
        }
    }

    updateAmounts() {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        console.log('updateAmounts')
        clearTimeout(this.delayTimer);

        this.isAmountErrors = this.coins.reduce((isError: boolean, coin: Currency) => {
            // console.log('isAmountErrors', isError, this.shouldShowError(this.client.balances[coin.address], this.amounts[coin.address]))
            return isError || this.shouldShowError(this.client.balances[coin.address], this.amounts[coin.address])
        }, false)

        this.delayTimer = setTimeout(async () => {

            try {
                if (!state.isReady) { throw new Error("State Not Loaded yet") }
    
                const amounts = this.coins.map(coin =>
                    new BigNumber(this.amounts[coin.address] || 0).times(coin.precision),
                );
    
                console.log('amounts', amounts.map(bn => bn.toFixed(0)));
    
                const isDeposit = true;

                const estimateLPTokensAmount = await state.swapContract?.methods.calc_token_amount(
                    amounts.map(bn => bn.toFixed(0)),
                    isDeposit,
                ).call();

                console.log('estimate', estimateLPTokensAmount);
    
                const lpToken = await initToken(state.web3, CONTRACT.xsigmaLpToken);
    
                const slippage = parseFloat(this.maxSlippage)
    
                console.log('estimateLPTokensAmount', new BigNumber(estimateLPTokensAmount).toString())
    
                this.minLPTokensAmount = new BigNumber(estimateLPTokensAmount)
                    .times(100 - slippage).div(100)
                    .div(lpToken.precision);
    
                console.log('minLPTokensAmount1', this.minLPTokensAmount.toString())

                const gasLimit = await state.swapContract.methods
                    .add_liquidity(
                        amounts.map(bn => bn.toFixed(0)),
                        this.minLPTokensAmount.times(lpToken.precision).toFixed(0),
                    )
                    .estimateGas({
                        from: state.defaultAccount,
                    })

                this.gasLimit = gasLimit || this.USE_GAS_LIMIT

                this.isAmountErrors = false

                console.log('estimated gas limit', gasLimit)

                // console.log('this.isAmountErrors', this.isAmountErrors)
            } catch (e) {
                console.error('error estimating gas: transaction will fail', e)

                this.gasLimit = this.USE_GAS_LIMIT

                // TODO: don't stop because maybe there is no allowance.
                // BUT, sometimes tx fails and we want to forbid running the transaction
                // this.isAmountErrors = true

            } finally {
                this.delayTimer = null
            }
        }, 1000)
    }

    UPDATE_SET_MAX_LIQUIDITY (coinAddress: any) {
        this.$set(this.amounts, coinAddress, this.client.balances[coinAddress].toString())
        this.activateOptimalProportions()
        this.updateAmounts()
    }

    UPDATE_SET_MAX_ALL_LIQUIDITY () {
        this.coins.map(coin => this.UPDATE_SET_MAX_LIQUIDITY(coin.address))
        // this.$set(this.amounts, coinAddress, this.client.balances[coinAddress])
    }

    doWithdrawAll () {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        
        const amount = this.setLiquidity3PoolToken;

        if (!amount) { return console.error('Not loaded') }

        // TODO: fetch precision instead of 1e18
        console.log('withdraw', amount.times(1e18));

        this.withdraw(amount.times(1e18));
    }

    async withdraw(amount: BigNumber, minAmounts?: BigNumber[]) {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        console.log('withdraw', 
            amount.toFixed(0),
            minAmounts,
            minAmounts?.map(bn => bn.toFixed(0)) || [0,0,0],
        );

        // TODO: fix estimateGas error
        // const gasLimit = await state.swapContract.methods
        //     .remove_liquidity(
        //         amount.toFixed(0),
        //         minAmounts?.map(bn => bn.toFixed(0)) || [0,0,0],
        //     ).estimateGas();

        // console.log('gasLimit', gasLimit);

        const gasPrice = this.gasPrice.times(1e9)

        const result = state.swapContract.methods
            .remove_liquidity(
                amount.toFixed(0),
                minAmounts?.map(bn => bn.toFixed(0)) || [0,0,0],
            )
            .send({
                from: state.defaultAccount,
                gasPrice,
                // gasLimit,
                gasLimit: this.USE_GAS_LIMIT,
            });

        console.log('result', result)

    }

    async addLiquidity() {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        if (this.isExchangePending) return;

        const amounts = this.coins.map(coin =>
            new BigNumber(this.amounts[coin.address] || 0).times(coin.precision),
        );

        const { isInfinite } = this;
        
        const gasPrice = this.gasPrice.times(1e9)

        const address = state.swapContractAddress;

        // waiting until all tokens are approved
        this.alertTitle = '';
        this.alertText = TRANSACTION_STATUS.checkingForApprove;
        this.showAlert = true;

        try {
            await Promise.all(this.coins.map((coin, index) =>
                checkOrApprove(coin.address, address, amounts[index], isInfinite, gasPrice)
            ))
        } catch (e) {
            this.$notify(Popup.plain.error(TRANSACTION_STATUS.approveFailed, e.message));
            return
        } finally {
            this.showAlert = false;
        }

        this.updateAmounts();

        const lpToken = await initToken(state.web3, CONTRACT.xsigmaLpToken);

        // TODO: run estimateGas
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const gasLimit = this.gasLimit;

        const minMintAmount = this.minLPTokensAmount.times(lpToken.precision).toFixed(0);

        let result;

        try {
            this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
            this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
            this.showAlert = true;

            result = state.swapContract.methods
              .add_liquidity(
                amounts.map(bn => bn.toFixed(0)),
                minMintAmount,
              )
              .send({
                  from: state.defaultAccount,
                  gasPrice,
                  gasLimit: this.USE_GAS_LIMIT,
              });
            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                this.showAlert = false;
                this.$notify(Popup.tx.success(TRANSACTION_STATUS.created, hash))
            })
            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.liquidityAdded, receipt.transactionHash))

                this.isExchangePending = false;
                this.showAlert = false;
                state.transactionPending = false;

                this.amounts = state.coins.reduce((obj, c) => ({
                    ...obj,
                    [c.address]: '',
                }), {})

                this.$store.dispatch('UPDATE_BALANCES')
                this.$store.dispatch('GET_BALANCE')
                this.updateAmounts()
                this.init()
            })
            result.on('error', (error: any) => {
                // TODO: always print error to the console
                console.error('error', error);

                // TODO: always print error message in the notification
                this.alertText = TRANSACTION_STATUS.stakeReverted;
                this.transactionError = true
                this.showAlert = true;
                this.isExchangePending = false;
                state.transactionPending = false
                setTimeout(() => {
                    this.showAlert = false
                    this.transactionError = false
                }, 2000)
            })
        } catch (e) {
            // TODO: always print error to the console
            console.error(e);

            // TODO: sometimes different error
            this.alertText = TRANSACTION_STATUS.stakeReverted;
            this.showAlert = true;
            this.isExchangePending = false;
            state.transactionPending = false
        }
    }
}
